import TextField from "app/shared/TextField";
import React, { useState } from "react";
import "./CreateWorkspace.scss";
import { Avatar, Button, Popover, Select, Tag, message } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { cursorTo } from "readline";
import {
  EditOutlined,
  CloseOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import MemberSearch from "app/shared/MemberSearch";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiGet, apiPost } from "app/services/apiServices";
import { API_ENDPOINT_SETTING_INVITE_MEMBER } from "../../settings.constants";
import { API_ENDPOINT_ONBOARDING_CREATE_WORKSPACE } from "app/scenes/Onboarding/onboarding.constants";
import AddBusiness from "app/scenes/Onboarding/components/AddBusiness";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";

export default function CreateWorkspace(props: any) {
  const [workspaceName, setWorkspaceName] = useState<any[]>([]);
  const [workspaceCreated, setWorkspaceCreated] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  const navigate = useNavigate();
  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const fetchUserInfo = async () => {
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      setUserDetails({
        ...userDetails,
        workspaces: response.data.workspaces,
      });
    }
  };

  const handleCreateWorkspace = async () => {
    setLoading(true);
    const response: any = await apiPost(
      API_ENDPOINT_ONBOARDING_CREATE_WORKSPACE,
      {
        name: workspaceName,
        is_active: "true",
      }
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      setWorkspaceCreated(true);
      fetchUserInfo();
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: response.message || "Something went wrong",
      });
    }
    setLoading(false);
  };

  return (
    <div className="CreateWorkspace">
      {workspaceCreated ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 28,
          }}
        >
          <Typography variant="h5">
            Hurry 🎉, you created a new workspace{" "}
          </Typography>
          <Typography
            variant="caption"
            style={{
              marginTop: 12,
              color: colorPicker("primary.800"),
              cursor: "pointer",
            }}
            onClick={() => navigate("/settings/members")}
          >
            Start adding members <ArrowRightOutlined />
          </Typography>
        </div>
      ) : (
        <div className="workspaceBasicInfo">
          <div style={{ width: "100%" }}>
            <TextField
              style={{ height: 40 }}
              placeholder="Workspace Name (eg Acme Corporation)"
              onChange={(e: any) => setWorkspaceName(e.target.value)}
            />
          </div>
          <Button
            size="large"
            style={{ marginTop: 24 }}
            type="primary"
            loading={isLoading}
            onClick={handleCreateWorkspace}
          >
            Create
          </Button>
        </div>
      )}

      {contextHolder}
    </div>
  );
}

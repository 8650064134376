import React from "react";
import "./FlightReports.scss";
import PageHeader from "app/shared/PageHeader";
import { AgTableClient } from "app/shared/AgTable";
export default function FlightReports(props: any) {
  return (
    <div className="FlightReports" style={{ height: "100%" }}>
      <iframe
        height="100%"
        style={{ width: "100%" }}
        scrolling="no"
        title="Iframe"
        src={
          "https://analytics.zoho.in/open-view/103074000027919816/2ba77f9f05c7d79a4354eaed6"
        }
      ></iframe>
    </div>
  );
}

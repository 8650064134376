import { Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { FormattedNumber } from "react-intl";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { get as getDataWithLodash } from "lodash";
import "./AgTable.scss";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import Typography from "../Typography";
import airlineLogos from "./HelperTools/IconMaps";
import AirlinesRoundedIcon from "@mui/icons-material/AirlinesRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
function formatFileSize(bytes: any) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export const handleAgCellDataFormat = (
  formatType: string,
  formatData: any,
  formatProps: any,
  fullParamData: any
) => {
  switch (formatType) {
    case "CUSTOM_RENDER":
      return formatProps?.cellRenderer(formatData, fullParamData) || "";
    case "CHIP":
      return <Tag color={formatProps?.chipColor}>{formatData}</Tag>;
    case "CHIP_SHORT_TEXT":
      return (
        <Tag color={formatProps?.chipColor}>
          {formatData.toString().slice(0, 8)}
        </Tag>
      );
    case "ENTITY":
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CorporateFareRoundedIcon style={{ fontSize: 19, marginRight: 4 }} />
          {formatData}
        </div>
      );
    case "PERSON":
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <GroupRoundedIcon style={{ fontSize: 19, marginRight: 4 }} />
          {formatData}
        </div>
      );
    case "AIRLINE":
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {formatData ? (
            <img
              src={airlineLogos[formatData]}
              style={{
                width: 30,
                height: 30,
                marginRight: 4,
                objectFit: "contain",
              }}
              alt="airlineLogo"
            />
          ) : (
            <AirlinesRoundedIcon />
          )}
          {formatData}
        </div>
      );
    case "AMOUNT":
      let amountData =
        typeof formatData === "string"
          ? parseFloat(formatData.replace(/,/g, ""))
          : formatData;
      return (
        <div
          style={{
            textAlign: "right",
            width: "100%",
            color: amountData < 0 ? "#dd5061" : "",
            fontWeight: amountData < 0 ? 700 : 400,
          }}
        >
          <FormattedNumber
            value={amountData || 0}
            style="currency"
            currency="INR"
          />
        </div>
      );
    case "FILE_SIZE":
      return formatData ? formatFileSize(formatData) : "0 B";

    case "DATE":
      console.log("date format data:", formatData);
      return moment(formatData).format(
        formatProps?.dateFormat || "DD-MM-YYYY"
      ) == "Invalid date" ? (
        <Tag color="red">Date Unavailable</Tag>
      ) : (
        moment(formatData).format(formatProps?.dateFormat || "DD-MM-YYYY")
      );

      return "NA";
    case "TAGS":
      let tags: any = [];
      (formatData || []).forEach((element: any) => {
        tags.push(
          <Tag
            // onClick={() => applyFilter(fieldKey, element)}
            color={formatProps?.chipColor}
            id="filterTag"
            style={{ fontSize: 10 }}
          >
            {element}
          </Tag>
        );
      });
      return tags;
    case "404":
      return formatData ? (
        formatData
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            color: "#dd5061",
            fontWeight: 700,
          }}
        >
          Missing
        </div>
      );
    case "MATCH":
      return (
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <Tag
            color={formatProps?.matchLogic?.matchColor[formatData] || "red"}
            style={{
              alignItems: "center",
              display: "flex",
              width: "fit-content",
              fontSize: 10,
            }}
          >
            {formatProps?.matchLogic?.icon?.[formatData] || (
              <CloseCircleFilled />
            )}
            <span />
            {formatData || formatProps?.defaultText || "NA"}
          </Tag>
        </div>
      );
    case "MATCH_COMPARE":
      let isMatched = true;
      (formatProps?.compareObjects || []).forEach((objectPath: string) => {
        if (formatData !== getDataWithLodash(fullParamData, objectPath, "NA")) {
          isMatched = false;
          return;
        }
      });

      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isMatched ? (
            <CheckCircleFilled style={{ color: "#43a522", marginRight: 6 }} />
          ) : (
            <CloseCircleFilled style={{ color: "#dd5061", marginRight: 6 }} />
          )}

          <span />
          {formatData || formatProps?.defaultText || "NA"}
        </div>
      );

    case "MATCH_COMPARE_STATUS":
      let isDataMatch = true;
      (formatProps?.compareObjects || []).forEach((objectPath: string) => {
        if (formatData !== getDataWithLodash(fullParamData, objectPath, "NA")) {
          isMatched = false;
          return;
        }
      });

      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isDataMatch ? (
            <CheckCircleFilled style={{ color: "#43a522", marginRight: 6 }} />
          ) : (
            <CloseCircleFilled style={{ color: "#dd5061", marginRight: 6 }} />
          )}

          <span />
          {isDataMatch ? "Match" : "Mismatch"}
        </div>
      );

    case "MATCH_COMPARE_TEXT":
      let isTextMatched = true;
      (formatProps?.compareObjects || []).forEach((objectPath: string) => {
        if (formatData !== getDataWithLodash(fullParamData, objectPath, "NA")) {
          isTextMatched = false;
          return;
        }
      });

      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            color: isTextMatched ? "" : "#dd5061",
            fontWeight: isTextMatched ? 400 : 700,
          }}
        >
          {formatData || formatProps?.defaultText || "NA"}
        </div>
      );
    default:
      return formatData;
  }
};

const handleValueGetter = (params: any, dataKey: string, defaultValue: any) => {
  if (params.colDef.formatType === "CUSTOM_RENDER") {
    return params?.colDef?.formatProps?.valueGetterData
      ? params?.colDef?.formatProps?.valueGetterData(params.data, params)
      : getDataWithLodash(params?.data, dataKey, "NA");
  } else {
    return getDataWithLodash(params?.data, dataKey, "NA");
  }
};
export const handleCellRenderer = () => {
  return {
    valueGetter: (params: any) => {
      if (params.colDef.colId && params.colDef.colId === "ag-Grid-AutoColumn") {
        // If the node is a group, return the group key
        return params.value;
      } else if (params.colDef.column && params.column.aggregationActive) {
        // If the node is a group, return the group key
        return params.value;
      } else if (params.node.group) {
        return "";
      } else {
        // If the node is a leaf node, return the individual value
        return handleValueGetter(params, params?.colDef?.field, "NA");
      }
    },
    cellRenderer: (params: any) => {
      return params.colDef.colId && params.colDef.colId === "ag-Grid-AutoColumn"
        ? handleAgCellDataFormat(
            params?.colDef?.formatType,
            params?.value,
            params?.colDef?.formatProps,
            params?.data
          )
        : params.colDef.colId && params.colDef.colId.includes("pivot")
        ? handleAgCellDataFormat(
            params?.colDef?.formatType,
            params?.value,
            params?.colDef?.formatProps,
            params?.data
          )
        : params.column && params.column.aggregationActive
        ? handleAgCellDataFormat(
            params?.colDef?.formatType,
            params?.value,
            // getDataWithLodash(params?.data, params?.colDef?.field, "NA"),
            params?.colDef?.formatProps,
            params?.data
          )
        : params.node.group
        ? "-"
        : handleAgCellDataFormat(
            params?.colDef?.formatType,
            getDataWithLodash(params?.data, params?.colDef?.field, "NA"),
            params?.colDef?.formatProps,
            params?.data
          );
    },
  };
};

import { colorPicker } from "app/utils/color.helper";
import { useCallback, memo } from "react";
import React from "react";

const ToolParamInvoker = (params: any) => {
  const { value, handleClick, label, data } = params;

  const onLinkClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      handleClick(params, { pdfData: data });
    },
    [handleClick, params]
  );

  return (
    <div onClick={onLinkClick} title={value}>
      <div
        role="button"
        className={`link-primary text-truncate `}
        style={{ cursor: "pointer" }}
      >
        {data ? "View Invoice" : ""}
      </div>
    </div>
  );
};

export default memo(ToolParamInvoker);

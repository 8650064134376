import { useEffect, useState } from "react";
import "../../AgTable.scss";
import Loader from "app/shared/Loader";
import { API_ENDPOINT_STORAGE_S3_SIGNED_URL } from "app/scenes/HotelBookingView/hotel.booking.constants";
import { apiGet } from "app/services/apiServices";
import { useRecoilState } from "recoil";
import { currentPDFData } from "app/config/States/users";
export default function PDFViewerToolV2(props: any) {
  const [pdfData, setPdfData] = useState<any>("");
  const [isLoading, setLoading] = useState(true);
  const [recoilPDFData, _] = useRecoilState<string>(currentPDFData);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const getS3SignedURL = async (urlData: any) => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_STORAGE_S3_SIGNED_URL + "?url=" + urlData
    );
    if (response.status) {
      setPdfData(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("pdfV2", recoilPDFData);
    if (recoilPDFData && !recoilPDFData.includes("files.finkraft")) {
      getS3SignedURL(recoilPDFData);
    } else {
      setPdfData(recoilPDFData);
    }
  }, [recoilPDFData]);

  return (
    <div className="PDFViewerToolSidebar">
      <div className="Container">
        <div className="PDFViewer" style={{ width: "100%" }}>
          {isLoading && <Loader />}

          <embed
            width="100%"
            height="100%"
            src={pdfData}
            type="application/pdf"
            id="pdfEmbed"
            onLoad={handleIframeLoad}
            style={{
              // display: isLoading ? "none" : "block",
              height: "100%",
              paddingBottom: 20,
            }}
          ></embed>
        </div>
      </div>
    </div>
  );
}

import PageHeader from "app/shared/PageHeader";
import "./Flights.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";

import { Button, Modal, Tabs, Tag } from "antd";

import {
  API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST,
  API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST,
  API_ENDPOINT_FETCH_EMAIL_CREDENTIALS_LIST,
  API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST,
} from "../Credentials/credentials.constants";
import { apiGet } from "app/services/apiServices";

import Loader from "app/shared/Loader";
import FlightDashboard from "./components/FlightDashboard";
import FlightRecon from "./components/FlightRecon";
import FlightReports from "./components/FlightReports";
export default function Flights(props: any) {
  const [activeTab, setActiveTab] = useState("1");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const items = [
    {
      key: "1",
      label: "Dashboard",
    },
    {
      key: "2",
      label: "Reconciliation",
    },
    // {
    //   key: "3",
    //   label: "Reports",
    // },
  ];

  const renderScene = () => {
    switch (activeTab) {
      case "1":
        return <FlightDashboard />;
      case "2":
        return <FlightRecon />;
      case "3":
        return <FlightReports />;
      default:
        return <FlightDashboard />;
    }
  };

  return (
    <div className="Flights">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <Tabs
              items={items}
              tabBarStyle={{
                borderBottom: "none", // Remove the underline border
                margin: 0,
              }}
              activeKey={activeTab}
              onChange={(tabItem: string) => setActiveTab(tabItem)}
            />
          }
        />

        <div className="TableContainer">{renderScene()}</div>
      </div>
    </div>
  );
}

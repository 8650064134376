import Dashboard from "app/scenes/Dashboard";
import MasterLayout from ".";
import ProtectedRoute from "app/utils/ProtectedRoute";
import credentialsRoutes from "app/scenes/Credentials/credentials.routes";
import reconcilationRoutes from "app/scenes/Reconcilation/reconcilation.routes";
import Invoices from "app/scenes/Invoices";
import Reports from "app/scenes/Reports";
import FinkTest from "app/scenes/FinkTest/FinkTest";
//@ts-ignore
import BookingData from "app/scenes/BookingData";
import { Navigate } from "react-router-dom";
import settingsRoutes from "app/scenes/Settings/settings.routes";
import Integrations from "app/scenes/Integrations";
import OnboardAirlineReconView from "app/scenes/AppTour/components/OnboardAirlineReconView";
import PageHeader from "../PageHeader";
import FlighAirlineReconZA from "app/scenes/Reconcilation/components/AirlineReconcilationZA";
import Flights from "app/scenes/Flights";
import Hotels from "app/scenes/Hotels/Hotels";
import HotelBookingViewClient from "app/scenes/HotelBookingViewClient/HotelBookingViewClient";
// import Onboarding from "app/scenes/Onboarding";

export default {
  path: "",
  element: (
    <ProtectedRoute>
      <MasterLayout />
    </ProtectedRoute>
  ),
  strict: true,
  children: [
    {
      path: "",
      element: <Navigate to="/dashboard" />, // Default redirect to the first child route
      exact: true,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      exact: true,
    },
    {
      path: "/fink",

      element: <FinkTest />,
      exact: true,
    },
    {
      path: "/invoices",

      element: <Invoices />,
      exact: true,
    },
    {
      path: "/bookings",

      element: <BookingData />,
      exact: true,
    },

    {
      path: "/flights",

      element: <Flights />,
      exact: true,
    },
    {
      path: "/hotels",

      element: <Hotels />,
      exact: true,
    },

    {
      path: "/integrations",

      element: <Integrations />,
      exact: true,
    },
    {
      path: "/hotel_followup",

      element: <HotelBookingViewClient />,
      exact: true,
    },
    {
      path: "/bookingflat",

      element: <FinkTest />,
      exact: true,
    },

    credentialsRoutes,
    reconcilationRoutes,
    settingsRoutes,
  ],
};

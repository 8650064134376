import PageHeader from "app/shared/PageHeader";
import "./Dashboard.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import Loader from "app/shared/Loader";
import { Empty } from "antd";
import Typography from "app/shared/Typography";
import EmptyState from "app/shared/EmptyState";
export default function Dashboard(props: any) {
  const [activeKey, setActiveKey] = useState(1);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [userDetails]);

  const getTabView = () => {
    let dashboards = [
      {
        report_name: "All Vendors",
        embedurl:
          "https://assets.finkraft.ai/open-view/103074000027682072/8f3c76fe9a7f779aebbc29aabcd6a3c3",
      },
      {
        report_name: "All Vendor Report",
        embedurl:
          "https://assets.finkraft.ai/open-view/103074000027553467/6c7a32a5c5feb899155f9e7a5bb5475f",
      },
    ];
    let tabs: any = [];
    let id = 1;
    dashboards.forEach((dashboard: any) => {
      tabs.push({
        title: dashboard.report_name,
        id: id,
        type: "VIEW",
        viewData: dashboard.embedurl,
      });
      id = id + 1;
    });

    return tabs;
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <CustomReconTabs
              activeKey={activeKey}
              title="Report"
              onTabChange={(tabKey: any) => setActiveKey(tabKey)}
              onTabModify={(tabs: any) =>
                setTabView({ ...tabViews, dashboard: tabs })
              }
              hideAddTab
              hideRemove
              defaultTabs={getTabView()}
              width={200}
            />
          }
        />

        <div className="TableContainer">
          {tabViews.dashboard?.length === 0 ? (
            <EmptyState
              title="No Dashboards Available"
              description={"Try changing workspace for dashboard view"}
            />
          ) : (
            (tabViews.dashboard || []).map((tab: any, key: any) => {
              return (
                <>
                  <div
                    style={{
                      height: "100%",
                      display: activeKey === tab.id ? "flex" : "none",
                    }}
                    key={key}
                  >
                    <iframe
                      height="100%"
                      style={{ width: "100%" }}
                      scrolling="no"
                      title="Iframe"
                      src={tab.viewData}
                    ></iframe>
                  </div>
                </>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

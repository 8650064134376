import React from "react";
import "./HotelDashboard.scss";
import PageHeader from "app/shared/PageHeader";
import { AgTableClient } from "app/shared/AgTable";
export default function HotelDashboard(props: any) {
  return (
    <div className="FlightDashboard" style={{ height: "100%" }}>
      <iframe
        height="100%"
        style={{ width: "100%" }}
        scrolling="no"
        title="Iframe"
        src={
          "https://assets.finkraft.ai/open-view/103074000024102200/91116c14cbe316559772afcfb168f11d"
        }
      ></iframe>
    </div>
  );
}

export const API_ENDPOINT_FETCH_HOTEL_BOOKING = `${process.env.REACT_APP_API_URL}/misc/bookingview/hotel`;
export const API_ENDPOINT_UPDATE_HOTEL_DETAIL = `${process.env.REACT_APP_API_URL}/misc/bookingview/hotel/updateinfo`;
export const API_ENDPOINT_TRIGGER_CUSTOM_MAIL =
  "http://localhost:8001/triggermail";

export const API_ENDPOINT_FETCH_TEMPLATE_INFO = `${process.env.REACT_APP_API_URL}/misc/sendgrid/template/`;

export const API_ENDPOINT_STORAGE_S3_UPLOAD_OPEN = `${process.env.REACT_APP_API_URL}/storage/objects/upload/open`;
export const API_ENDPOINT_GET_REF_TOKEN_INFO = `${process.env.REACT_APP_API_URL}/misc/bookingview/hotel/update/tokenInfo`;
export const API_ENDPOINT_HOTEL_BOOKING_UPDATE_INVOICE_INFO = `${process.env.REACT_APP_API_URL}/misc/bookingview/hotel/update/invoice`;

export const CUSTOM_MAIL_SEND_ACTIONS: any = {
  BOOKING: "Send Checkin Mail",
  PENDING: "Send Booking Mail",
  CHECKIN: "Send Checkout Reminder 1",
  CHECKOUT_1: "Send Checkout Reminder 2",
  CHECKOUT_2: "Send Checkout Mail",
};
export const API_ENDPOINT_STORAGE_S3_SIGNED_URL = `${process.env.REACT_APP_API_URL}/storage/objects/url/signed`;

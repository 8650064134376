import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import { useEffect, useRef, useState } from "react";

import Loader from "app/shared/Loader";
import { PlusOutlined, ExportOutlined } from "@ant-design/icons";
import CircleIcon from "@mui/icons-material/Circle";
import { Avatar, Button, Empty, Modal, Spin, Tabs, Tag, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import Typography from "app/shared/Typography";
import "../../Settings.scss";
import PageHeader from "app/shared/PageHeader";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import TableViewSelector from "app/shared/TableViewSelector";
import { API_ENDPOINT_SETTING_WORKSPACE_LIST } from "../../settings.constants";
import { colorPicker } from "app/utils/color.helper";
import CreateWorkspace from "../CreateWorkspace";
import color from "theme/color";
import { useNavigate } from "react-router-dom";
export default function WorkspaceSetting(props: any) {
  const [tableData, setTableData] = useState([]);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const handleSelectWorkspace = (workspaceInfo: any) => {
    localStorage.setItem("currentWorkspace", workspaceInfo?.id);
    setUserDetails({ ...userDetails, currentWorkspace: workspaceInfo });

    navigate("/bookings");
  };

  const initialColDefs = [
    {
      field: "name",
      headerName: "Workspace",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return (
          <Typography
            style={{ cursor: "pointer", color: colorPicker("neutral.800") }}
            variant="xs"
            onClick={() => handleSelectWorkspace(params.data)}
          >
            {params.data?.name}
          </Typography>
        );
      },
    },
    {
      field: "users",
      headerName: "Users",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return (
          <Avatar.Group
            //@ts-ignore
            max={{
              count: 2,
              style: { color: "#f56a00", backgroundColor: "#fde3cf" },
            }}
            maxCount={2}
            size={24}
          >
            {(params?.data?.users || []).map((user: any, key: any) => {
              return (
                <Avatar key={key} style={{ backgroundColor: "#f56a00" }}>
                  {user.name[0]}
                </Avatar>
              );
            })}
          </Avatar.Group>
        );
      },
    },
    {
      field: "pan",
      headerName: "PANs",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "is_active",
      headerName: "Status",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return (
          <Tag style={{ display: "flex", alignItems: "center" }}>
            <CircleIcon
              style={{
                fontSize: 10,
                color: colorPicker(
                  params?.data?.is_active ? "green.700" : "red.700"
                ),
              }}
            />
            <Typography
              variant="xs"
              style={{ color: colorPicker("neutral.700"), marginLeft: 4 }}
            >
              {params?.data?.is_active ? "Active" : "Inactive"}
            </Typography>
          </Tag>
        );
      },
    },
  ];
  useEffect(() => {
    setColumnDefs(initialColDefs);
  }, []);
  useEffect(() => {
    fetchWorkspaceList();
  }, [userDetails.currentWorkspace]);
  const fetchWorkspaceList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_SETTING_WORKSPACE_LIST);
    if (response.status) {
      setTableData(response.data);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleExportTableData = () => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };

  const handleSuccess = () => {
    setShowModal(false);
    fetchWorkspaceList();
  };

  return (
    <div className="ScreenContainer">
      <PageHeader
        title="Workspace"
        rightActions={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => setShowModal(true)}
            >
              Create Workspace
            </Button>
          </div>
        }
      />
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : tableData.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty />
          </div>
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={columnDefs}
            groupPanel
            // onRowSaved={handleRowEdit}
            ref={gridRef}
            footer={false}
            autoResize
          />
        )}
      </div>

      <Modal
        title="Add Workspace"
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={600}
      >
        <CreateWorkspace onSuccess={handleSuccess} />
      </Modal>
      {contextHolder}
    </div>
  );
}

import React from "react";
import "./FlightDashboard.scss";
import PageHeader from "app/shared/PageHeader";
import { AgTableClient } from "app/shared/AgTable";
export default function FlightDashboard(props: any) {
  return (
    <div className="FlightDashboard" style={{ height: "100%" }}>
      <iframe
        height="100%"
        style={{ width: "100%" }}
        scrolling="no"
        title="Iframe"
        src={
          "https://assets.finkraft.ai/open-view/103074000028419297/2dc6e01dd32f3cbc99ce9ef6ddb6d7bd"
        }
      ></iframe>
    </div>
  );
}
